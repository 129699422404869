import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';

export default function Contact () {
    return (
        <div>
            <Card className='bg-primary-100' title='CHOOSE YOUR DREAM CAR' />
            <p className="text-xl font-bold pl-3">CONTACT US</p>
            <div className='pl-3 pt-3 text-lg'>
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        Office Phone
                    </div>
                    <div className='col'>
                        xxx-xxx-xxxx
                    </div>
                </div>
                <Divider />
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        After Hours Phone
                    </div>
                    <div className='col'>
                        xxx-xxx-xxxx
                    </div>
                </div>
                <Divider />
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        Email
                    </div>
                    <div className='col'>
                        yourname@company.com
                    </div>
                </div>
                <Divider />
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        Fax
                    </div>
                    <div className='col'>
                        xxx-xxx-xxxx
                    </div>
                </div>
                <Divider />
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        Address
                    </div>
                    <div className='col'>
                        1234 The Company Rd<br/>Avon, OH 44011
                    </div>
                </div>
                <Divider />
                <div className='grid grid-nogutter'>
                    <div className='col-3'>
                        Business Hours
                    </div>
                    <div className='col'>
                        M-F 9am-5pm
                    </div>
                </div>
            </div>
        </div>
    );
}