import { Card } from 'primereact/card';

export default function BuyerInfo(){
    return(
        <div>
            <Card className='bg-primary-100' title='CHOOSE YOUR DREAM CAR' />
            <p className="text-xl font-bold pl-3">BUYER INFO</p>
            <div className='text-5xl text-center'>
                Under Construction
            </div>
        </div>  
    );
}