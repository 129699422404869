import { Card } from 'primereact/card';

export default function About () {
    return (
        <div>
            <Card className='bg-primary-100' title='CHOOSE YOUR DREAM CAR' />
            <p className="text-xl font-bold pl-3">ABOUT US</p>
            <div className='text-5xl text-center'>
                Under Construction
            </div>
        </div>
    );
}