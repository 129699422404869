import { useState, useEffect } from "react";
import React from "react";
import 'primeflex/primeflex.css';
import { CarService } from "./services/CarService";
import { Tag } from 'primereact/tag';
import { DataView } from 'primereact/dataview';
import { Card } from 'primereact/card';
import { Link } from "react-router-dom";

export default function CarsList(){
    const [products, setProducts] = useState([]);

    useEffect(() => {
        CarService.getCarsData().then((data) => setProducts(data));
        //console.log(data);
        //setProducts(data);
    }, []);

    const getSeverity = (product) => {
        switch (product.status) {
            case 'Ready':
                return 'success';
            case 'Low Stock':
                return 'warning';
            case 'Out of Stock':
                return 'error';
            default:
                return null;
        }
    };

    const itemTemplate = (product) => {
        return (
            <div className="col-9">
                <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <img className="w-9 sm:w-16rem xl:w-18rem shadow-2 block xl:block mx-auto border-round" src={`/images/${product.stock_id}/01.jpeg`} alt={product.stock_id} />
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            <Link to={`/cardetails/${product.stock_id}`} className="text-2xl font-bold text-900">{product.name}</Link>
                            <div className="text-xl text-900">Miles: {product.miles.toLocaleString()}</div>
                            <div className="flex flex-items-center gap-3">
                                <span className="flex flex-items-center gap-2">
                                    <i className="pi pi-tag"></i>
                                    <span className="font-semibold">{product.category}</span>
                                </span>
                                <Tag value={product.status} severity={getSeverity(product)}></Tag>
                            </div>
                        </div>
                        <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                            <span className="text-2xl font-semibold">${product.sale_price.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Card className="bg-primary-100" title='ELEVATE YOUR DRIVE, REDEFINE YOUR JOURNEY - CHOOSE FROM THE BEST AT OUR CAR HAVEN' />
            <p className="text-xl font-bold pl-3">AVAILABLE CARS</p>
            <div className="card">
                <DataView value={products} itemTemplate={itemTemplate} />
            </div>
        </div>
    )
}