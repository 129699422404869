import React from "react";
import { MegaMenu } from 'primereact/megamenu';

export default function Menu() {
    const items = [
        {
            label: 'VEHICLES', 
            url: '/'
        }, 
        {
            label: 'BUYER INFO', 
            url: './buyerinfo'
        }, 
        {
            label: 'FAQ', 
            url: './faq'
        }, 
        {
            label: 'ABOUT', 
            url: './about'
        }, 
        {
            label: 'CONTACT', 
            url: './contact'
        }
    ];

    return (
        <div className="card">
            <MegaMenu model={items} start={<p className="bg-primary font-semibold pl-2 pr-2 pt-1 pb-1">MOTOR CAR CHOICE</p>}></MegaMenu>
        </div>
    )
}