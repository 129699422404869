import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import Menu from './Menu';
import CarsList from './CarsList';
import BuyerInfo from './pages/BuyerInfo';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FAQ from './pages/FAQ';
import About from './pages/About';
import Contact from './pages/Contact';
import CarDetails from './pages/CarDetails';

function App() {
  return (
    <div>
      <Menu />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CarsList />} />
          <Route index element={<CarsList />} />
          <Route path="/buyerinfo" element={<BuyerInfo />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/cardetails/:id' element={<CarDetails />} />
          <Route path="*" element={<CarsList />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
