import { Card } from 'primereact/card';

export default function FAQ () {
    return(
        <div>
            <Card className='bg-primary-100' title='CHOOSE YOUR DREAM CAR' />
            <p className="text-xl font-bold pl-3">FREQUENTLY ASKED QUESTIONS</p>
            <div className='text-5xl text-center'>
                Under Construction
            </div>
        </div>

    );
}