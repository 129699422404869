import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { CarService } from "../services/CarService";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { ImageService } from "../services/ImageService";

export default function CarDetails() {
    var { id } = useParams();
    var [car, setCar] = useState(null);

    useEffect(() => {
        CarService.getCarData(id).then((data) => setCar(data));
        ImageService.getImages(id);
    }, [id]);

    if (car != null) {
        return (
            <div>
                <Card className='bg-primary-100' title='CHOOSE YOUR DREAM CAR' />
                <p className="text-2xl font-bold pl-3">{car.name}</p>
                <div className="p-3 grid">
                    <div className="col">
                        <p className="text-2xl font-bold">Information</p>
                        <div className="grid">
                            <div className="col">
                                Year
                            </div>
                            <div className="col">
                                {car.year}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Make
                            </div>
                            <div className="col">
                                {car.make}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Model
                            </div>
                            <div className="col">
                                {car.model}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                VIN
                            </div>
                            <div className="col">
                                {car.vin}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Miles
                            </div>
                            <div className="col">
                                {car.miles}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Engine
                            </div>
                            <div className="col">
                                {car.engine}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Transmission
                            </div>
                            <div className="col">
                                {car.transmission}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Exterior Color
                            </div>
                            <div className="col">
                                {car.exterior_color}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Interior Color
                            </div>
                            <div className="col">
                                {car.interior_color}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Title
                            </div>
                            <div className="col">
                                {car.title}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <p className="text-2xl font-bold">Condition</p>
                        <div className="grid">
                            <div className="col">
                                Status
                            </div>
                            <div className="col">
                                {car.status}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Driveable
                            </div>
                            <div className="col">
                                {car.driveable}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Exterior
                            </div>
                            <div className="col">
                                {car.exterior}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Interior
                            </div>
                            <div className="col">
                                {car.interior}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Stains
                            </div>
                            <div className="col">
                                {car.stains}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Tears
                            </div>
                            <div className="col">
                                {car.tears}
                            </div>
                        </div>
                        <Divider />
                        <div className="grid">
                            <div className="col">
                                Burns
                            </div>
                            <div className="col">
                                {car.burns}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}