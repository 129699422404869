export const CarService = {
    getCarsData() {
        //var data = require('/cars_list.json');

        //return data;

        return fetch('/cars_list.json')
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
    },

    getCarData(id) {
        return this.getCarsData().then((carsData) => {
            var data = carsData;

            var filteredData = data.filter(element => element.stock_id === Number(id));

            if(filteredData.length > 0) {
                return filteredData[0];
            }
            else {
                return null;
            }
        });
    }
}